<div
  class="gated-entry-overlay"
  ngClass.lt-md="mobile"
  fxLayout="column"
  zelisTrapFocus
  autoTrap="true"
>
  <div class="gated-entry-header" fxFlex="nogrow">
    <app-overlay-header
      (goToOverlayPage)="
        !gatedEntryHomePageDisabled && onGoToOverlayPage($event)
      "
    >
    </app-overlay-header>
  </div>
  <div class="gated-entry-body" fxFlex="grow">
    <platform-page-container
      [fullscreen]="media.isActive('lt-md')"
      [contentSpacing]="false"
    >
      <div
        [class.hidden]="
          this.pageTransition.page === 'home' && breakpoints.isMobile
        "
        class="flex items-center h-[72px] px-4 mb-4 z-10"
      >
        <zelis-button
          *ngIf="showBackButton()"
          size="large"
          variant="basic"
          color="system-blue"
          (clickFn)="onGoBack()"
          data-cy="gated-entry-overlay-back-button"
        >
          <mat-icon
            svgIcon="dls-angle-plain-left-light"
            zelisButtonIcon
          ></mat-icon>
          {{ 'app_global_back' | translate }}
        </zelis-button>
      </div>
      <div [class.mt-20]="breakpoints.isDesktop" class="body-container">
        <div class="page-transition-stage">
          <app-gated-entry-overlay-home
            *ngIf="pageTransition.page === 'home'"
            [initialAppParams]="data.initialAppParams"
            [@slideInOutAnimation]="pageTransition.direction"
            (goToOverlayPage)="onGoToOverlayPage($event)"
          >
          </app-gated-entry-overlay-home>
          <app-gated-entry-overlay-network-location
            *ngIf="
              pageTransition.page === 'network-location' ||
              pageTransition.page === 'network-select'
            "
            [initialAppParams]="data.initialAppParams"
            [gatedEntryConfig]="data.gatedEntryConfig"
            [loggedIn]="data.loggedIn"
            [@slideInOutAnimation]="pageTransition.direction"
            [pageTransition]="pageTransition"
            (goToOverlayPage)="onGoToOverlayPage($event)"
            (goBack)="onGoBack()"
            (closeOverlay)="closeOverlay()"
            data-cy="gated-entry-overlay-home-network-location"
          >
          </app-gated-entry-overlay-network-location>
          <app-gated-entry-overlay-alpha-prefix
            *ngIf="
              pageTransition.page === 'alpha-prefix-location' ||
              pageTransition.page === 'alpha-prefix'
            "
            [initialAppParams]="data.initialAppParams"
            [gatedEntryConfig]="data.gatedEntryConfig"
            [loggedIn]="data.loggedIn"
            [@slideInOutAnimation]="pageTransition.direction"
            [pageTransition]="pageTransition"
            (goToOverlayPage)="onGoToOverlayPage($event)"
            (goBack)="onGoBack()"
            (closeOverlay)="closeOverlay()"
          >
          </app-gated-entry-overlay-alpha-prefix>
        </div>
      </div>
    </platform-page-container>
  </div>
</div>
