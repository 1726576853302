import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Results } from '@interfaces/results.interface';
import { SavedProvider } from '@interfaces/saved-provider.model';
import { SavedProvidersBtnData } from '@interfaces/saved-providers-btn-data.interface';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppParamsService } from '@services/app.params.service';
import { AuthService } from '@services/auth.service';
import { MembersService } from '@services/members.service';
import { SettingsService } from '@services/settings.service';
import { AppState } from '@state/app.state';
import {
  addProviderToSavedProviders,
  removeProviderFromSavedProviders,
  setSavedProvidersDisabled,
  setSavedProvidersEnabled,
} from '@store/saved-providers/saved-providers.actions';
import {
  getSavedProvidersBtnData,
  getSavedProvidersData,
} from '@store/saved-providers/saved-providers.selectors';
import {
  SnackBarConfig,
  SnackBarOptions,
  SnackbarService,
} from '@zelis/dls/snackbar';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SavedProvidersService {
  public listUpdatedSinceWarning = new BehaviorSubject(true);

  private readonly notAuthenticated = new Subject();
  private readonly savedProvidersURL = '/api/saved_providers.json';
  private readonly providersSummaryURL = '/api/providers/summary.json';

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private authService: AuthService,
    private appParams: AppParamsService,
    private store: Store<AppState>,
    private snackbarService: SnackbarService,
    @Inject(Router) private router: Router,
    private translateService: TranslateService,
    private membersService: MembersService
  ) {}

  public initializeSavedProviders(): Observable<boolean> {
    return this.getResolvedAuthStatus().pipe(
      takeUntil(this.notAuthenticated),
      tap((authenticated) => {
        if (!authenticated) {
          this.unsubscribeAndDisable();
        }
      }),
      filter((authenticated) => authenticated),
      switchMap(() => this.getSavedProvidersSetting()),
      switchMap(({ enabled, max }) => {
        if (enabled) {
          return this.appParams.listenToSelectedMemberNumber().pipe(
            distinctUntilChanged(),
            map((memberNumber) => ({ member_number: memberNumber, max: max }))
          );
        }
        return of(null);
      }),
      tap((data) => {
        if (data) {
          this.dispatchSavedProvidersEnabled(data);
        } else {
          this.dispatchSavedProvidersDisabled();
        }
      }),
      map((enabled) => !!enabled)
    );
  }

  public savedProvidersData(): Observable<any> {
    return this.store.pipe(select(getSavedProvidersData)).pipe(
      switchMap((providerData) => {
        const savedProviders = [...providerData.savedProviders];
        savedProviders.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        const sortedCCIDs = savedProviders.map((x) => x.client_canonical_id);

        if (sortedCCIDs.length === 0) {
          return of({
            savedProviders: [],
            max: providerData.max,
            removedProviders: false,
          });
        }

        return this.requestSavedProvidersDetails(sortedCCIDs).pipe(
          map((providerDetails) => {
            if (providerDetails?.providers?.length > 0) {
              const sortedProviders = providerDetails.providers.sort(
                (a, b) =>
                  sortedCCIDs.indexOf(a.client_canonical_id) -
                  sortedCCIDs.indexOf(b.client_canonical_id)
              );
              return {
                savedProviders: sortedProviders,
                max: providerData.max,
                removedProviders: sortedCCIDs.length > sortedProviders.length,
              };
            }

            return {
              savedProviders: [],
              max: providerData.max,
              removedProviders:
                sortedCCIDs.length > providerDetails?.providers?.length,
            };
          })
        );
      })
    );
  }

  public savedProvidersButtonData(): Observable<SavedProvidersBtnData> {
    return this.store.pipe(
      select(getSavedProvidersBtnData),
      switchMap((savedProvidersBtnData) => {
        return this.primaryMemberSelected().pipe(
          map((isPrimaryMember) => {
            return {
              ...savedProvidersBtnData,
              disableBtn: !isPrimaryMember,
            };
          })
        );
      })
    );
  }

  public addProvider(CCID: string, providerName: string): void {
    this.store.dispatch(
      addProviderToSavedProviders({
        providerCCID: CCID,
        providerName: providerName,
      })
    );
  }

  public removeProvider(
    savedProvider: SavedProvider,
    providerName: string
  ): void {
    this.store.dispatch(
      removeProviderFromSavedProviders({
        savedProvider: savedProvider,
        providerName: providerName,
      })
    );
  }

  public requestSavedProviders(memberNumber: string): Observable<any> {
    const params = { member_number: memberNumber, cache: 'false' };
    return this.http.get(this.savedProvidersURL, {
      params: params,
      withCredentials: true,
    });
  }

  public postSavedProviders(
    CCID: string,
    memberNumber: string
  ): Observable<any> {
    const postData = { client_canonical_id: CCID, member_number: memberNumber };
    return this.http.post(this.savedProvidersURL, postData, {
      withCredentials: true,
    });
  }

  public deleteSavedProviders(
    CCID: string,
    memberNumber: string
  ): Observable<any> {
    const params = { member_number: memberNumber, client_canonical_id: CCID };
    return this.http.delete(this.savedProvidersURL, {
      params: params,
      withCredentials: true,
    });
  }

  public alertAddSavedProviderSuccess(providerName: string) {
    this.openSnackBar(
      this.snackBarConfig(
        true,
        true,
        this.translateService.instant('saved_providers_add_alert_success', {
          providerName: providerName,
        })
      )
    );
  }

  public alertAddSavedProviderFailure(): void {
    this.openSnackBar(
      this.snackBarConfig(
        false,
        false,
        this.translateService.instant('saved_providers_add_alert_failure')
      )
    );
  }

  public alertRemoveSavedProvidersSuccess(providerName: string) {
    this.openSnackBar(
      this.snackBarConfig(
        true,
        false,
        this.translateService.instant('saved_providers_remove_alert_success', {
          providerName: providerName,
        })
      )
    );
  }

  public alertRemoveSavedProvidersFailure(): void {
    this.openSnackBar(
      this.snackBarConfig(
        false,
        false,
        this.translateService.instant('saved_providers_remove_alert_failure')
      )
    );
  }

  public alertMaxReached(): void {
    this.openSnackBar(
      this.snackBarConfig(
        false,
        true,
        this.translateService.instant('saved_providers_alert_max_reached')
      )
    );
  }

  private requestSavedProvidersDetails(CCIDs: string[]): Observable<Results> {
    const params = CCIDs.map((x) => `identifier[]=${x}`).join('&');
    return this.http.get(`${this.providersSummaryURL}?${params}`, {
      withCredentials: true,
    });
  }

  private snackBarConfig(
    success: boolean,
    viewProfileLink: boolean,
    message: string
  ): SnackBarConfig {
    const config = {
      data: {
        message: message,
        icon: success
          ? 'dls-check-circle-light'
          : 'dls-exclamation-circle-light',
        closeBtn: true,
        cta: null,
      } as SnackBarOptions,
    } as SnackBarConfig;

    if (viewProfileLink) {
      config.data.cta = {
        label: this.translateService.instant('saved_providers_view_profile'),
        callback: () => this.goToProfile(),
      };
    }
    return config;
  }

  private openSnackBar(config: SnackBarConfig) {
    this.snackbarService.open(config);
  }

  private goToProfile() {
    this.router.navigate(['account'], { queryParamsHandling: 'preserve' });
  }

  private getResolvedAuthStatus(): Observable<boolean> {
    return this.authService.authStatus.pipe(
      filter((authStatus) => authStatus.resolved),
      map((authStatus) => authStatus.auth_status),
      take(1)
    );
  }

  private getSavedProvidersSetting(): Observable<{
    enabled: boolean;
    max: number;
  }> {
    return this.settingsService.getSetting('saved_providers').pipe(
      map((setting) => {
        return {
          enabled: !!setting?.enabled,
          max: setting?.max || 10,
        };
      })
    );
  }

  private dispatchSavedProvidersDisabled(): void {
    this.store.dispatch(setSavedProvidersDisabled());
  }

  private dispatchSavedProvidersEnabled(data: {
    member_number: string;
    max: number;
  }): void {
    this.store.dispatch(setSavedProvidersEnabled(data));
  }

  private unsubscribeAndDisable(): void {
    this.dispatchSavedProvidersDisabled();
    this.notAuthenticated.next(undefined);
  }

  private primaryMemberSelected(): Observable<boolean> {
    return this.appParams.listenToSelectedMemberNumber().pipe(
      distinctUntilChanged(),
      map((memberNumber) => {
        const primaryMemberNumber =
          this.membersService.getDependentByNumber(null).member_number;
        return primaryMemberNumber === memberNumber;
      })
    );
  }
}
